<template>
  <main>
    <PageTitleContainer>
      <template #default>
        <PageTitle>Tipologie di vino</PageTitle>
      </template>
      <template #actions>
        <router-link class="btn btn-outline-primary" :to="{ name: 'inventory.wine-types.add' }">Aggiungi</router-link>
      </template>
    </PageTitleContainer>

    <FetchList :loading="loading">
      <div class="container">
        <AwlTable v-if="wineTypes && wineTypes.length > 0" :heading="tableHeadings" :rows="wineTypes" striped>
          <template #column-key-code="{ value }">
            <span class="text-uppercase">{{ value }}</span>
          </template>

          <template #actions-content="{ row }">
            <ActionShow :to="{ name: 'inventory.wine-types.show', params: { id: row.id } }" />
          </template>
        </AwlTable>
      </div>
    </FetchList>
  </main>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import FetchList from '@/views/components/Layout/FetchList.vue';
import AwlTable from '@/libs/Table/components/AwlTable.vue';
import ActionShow from '@/libs/Table/components/Actions/ActionShow.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import PageTitle from '@/views/components/Private/PageTitle.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  $api.listWineTypes()
    .then(res => next(vm => {
      vm.wineTypes = res?.data?.wineTypes || [];
    }))
    .catch(() => next(from))
  ;
}

export default {
  components: { FetchList, AwlTable, ActionShow, PageTitleContainer, PageTitle },
  data () {
    return {
      loading: false,
      wineTypes: null,
    };
  },
  computed: {
    tableHeadings () {
      return [
        { key: 'name', label: 'Name' },
      ];
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
